<template>
  <header>
    <h1>Jobs in India</h1>
  </header>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
});
</script>

<style scoped>
header {
  background-color: #f45b69;
  color: #fff;
  padding: 20px;
  text-align: center;
}
</style>
