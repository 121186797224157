import { ExperienceCategory } from "./components/Constants";

export const jobsList = [
  {
    domain: "QA",
    specialization: "Automation",
    company_name: "Capgemini",
    job_title: "QA Automation Engineer",
    salary: "Salary offered - 12LPA+",
    experience: "Exp : 4-9 years",
    city: "Bangalore",
    wfh: "Hybrid",
    glassdoor_rating: "Glassdoor: 3.8/5",
    jobLink:
      "https://www.instahyre.com/job-316398-qa-automation-engineer-at-capgemini-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Backend",
    specialization: "Java",
    company_name: "Coffee Beans",
    job_title: "Java Developer",
    salary: "Salary offered - 10LPA+",
    experience: "Exp : 5-10 years",
    city: "Bangalore",
    wfh: "Hybrid",
    glassdoor_rating: "Glassdoor: 4.1/5",
    jobLink:
      "https://www.instahyre.com/job-316370-java-developer-at-coffeebeans-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Others",
    specialization: null,
    company_name: "Recro",
    job_title: "Salesforce Developer",
    salary: "Salary offered - 23LPA+",
    experience: "Exp : 4-9 years",
    city: "Bangalore",
    wfh: "Hybrid",
    glassdoor_rating: "Glassdoor: 4.2/5",
    jobLink:
      "https://www.instahyre.com/job-316403-salesforce-developer-at-recro-2-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Fullstack",
    specialization: null,
    company_name: "Salesforce",
    job_title: "Fullstack Developer",
    salary: "Salary offered - 24 to 40LPA",
    experience: "Exp : 5-9 years",
    city: "Bangalore, Hyderabad",
    wfh: "Hybrid",
    glassdoor_rating: "Glassdoor: 4.1/5",
    jobLink:
      "https://www.instahyre.com/job-316293-software-engineer-at-salesforce-bangalore-hyderabad/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Internship",
    specialization: null,
    company_name: "Salesforce",
    job_title: "Software Enginner - Internship",
    salary: "Stipend - 13,000 per month",
    experience: "Duration : 6 Months",
    city: "Bangalore, Hyderabad",
    wfh: "Hybrid",
    glassdoor_rating: "Glassdoor: 4.1/5",
    jobLink:
      "https://www.instahyre.com/job-316294-software-engineer-internship-at-salesforce-bangalore-hyderabad/",
    experience_category: [ExperienceCategory.Fresher],
  },
  {
    domain: "Others",
    specialization: null,
    company_name: "Deloitte",
    job_title: "Magento Developer",
    salary: "Salary offered - 10LPA+",
    experience: "Exp: 2-6 years",
    city: "Bangalore",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-316278-magento-developer-at-deloitte-bangalore/",
    experience_category: [
      ExperienceCategory.Associate,
      ExperienceCategory.Senior,
    ],
  },
  {
    domain: "Others",
    specialization: null,
    company_name: "Tiger Analytics",
    job_title: "Business Analyst",
    salary: "Salary offered - 18LPA+",
    experience: "Exp: 4-6 years",
    city: "Pune",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-316302-business-analyst-at-tiger-analytics-pune/",
    experience_category: [ExperienceCategory.Senior],
  },
  {
    domain: "Frontend",
    specialization: "Reactjs",
    company_name: "Synechron",
    job_title: "Frontend Developer (React)",
    salary: "Salary offered - 12LPA+",
    experience: "Exp: 5-7 years",
    city: "Bangalore",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-316339-reactjs-developer-at-synechron-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "QA",
    specialization: "Automation",
    company_name: "Infosys",
    job_title: "QA Automation Engineer",
    salary: "Salary offered - 10LPA+",
    experience: "Exp: 5-10years",
    city: "Pune",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-316188-qa-automation-engineer-at-infosys-4-pune/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Devops",
    specialization: null,
    company_name: "CoinDCX",
    job_title: "DevOps Engineer 3",
    salary: "Salary offered - 14LPA+",
    experience: "4-7 Years",
    city: "Bangalore",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-316125-devops-engineer-3-at-coindcx-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Fullstack",
    specialization: null,
    company_name: "NeoSOFT Technologies",
    job_title: "Mern Stack Developer",
    salary: "Salary offered - 12LPA+",
    experience: "4-6 Years",
    city: "Pune",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-316165-mern-stack-developer-at-neosoft-technologies-pune/",
    experience_category: [ExperienceCategory.Senior],
  },
  {
    domain: "Devops",
    specialization: null,
    company_name: "Scaler Academy",
    job_title: "DevOps Engineer",
    salary: "Salary offered - 24LPA+",
    experience: "5-9 Years",
    city: "Work from home",
    wfh: "Remote",
    jobLink:
      "https://www.instahyre.com/job-316195-devops-engineer-at-scaler-academy-work-from-home/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Fullstack",
    specialization: null,
    company_name: "Swiggy",
    job_title: "SDE - III",
    salary: "Salary offered - 40LPA+",
    experience: "5-9 Years",
    city: "Work from home",
    wfh: "Remote",
    jobLink:
      "https://www.instahyre.com/job-316337-sde-iii-at-swiggy-work-from-home/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Backend",
    specialization: "Java",
    company_name: "Google",
    job_title: "Java Developer",
    salary: "Salary offered - 27LPA+",
    experience: "4-9 Years",
    city: "Multiple locations",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-116518-java-developer-at-google-2-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Devops",
    specialization: null,
    company_name: "Amazon",
    job_title: "DevOps Engineer",
    salary: "Salary offered - 25LPA+",
    experience: "3-5 Years",
    city: "Bangalore",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-310521-devops-engineer-at-amazon-bangalore/",
    experience_category: [ExperienceCategory.Senior],
  },
  {
    domain: "Backend",
    specialization: "Java",
    company_name: "Amazon",
    job_title: "Senior Software Engineer - Backend",
    salary: "Salary offered - 38LPA+",
    experience: "4-8 Years",
    city: "Bangalore",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-314410-senior-software-engineer-backend-at-amazon-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Backend",
    specialization: "Java",
    company_name: "Amazon",
    job_title: "Senior Software Engineer - Backend",
    salary: "Salary offered - 38LPA+",
    experience: "4-8 Years",
    city: "Bangalore",
    wfh: "Hybrid",
    jobLink:
      "https://www.instahyre.com/job-314410-senior-software-engineer-backend-at-amazon-bangalore/",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Data Analyst",
    company_name: "Prodigal",
    job_title: "Data Analyst",
    salary: "Salary offered - 12LPA+",
    experience: "1-3Years",
    city: "Mumbai",
    wfh: "In Office",
    Column11:
      "https://www.naukri.com/job-listings-data-analyst-prodigal-mumbai-1-to-3-years-100524502062?src=seo_srp&sid=17158871129641640&xp=15&px=1",
    experience_category: [ExperienceCategory.Associate],
  },
  {
    domain: "Data Analyst",
    company_name: "Accenture",
    job_title: "Data Engineer",
    salary: "Salary offered - 18LPA+",
    experience: "5-7 years",
    city: "Pune",
    wfh: "Hybrid",
    Column11:
      "https://www.naukri.com/job-listings-data-engineer-accenture-solutions-pvt-ltd-pune-5-to-7-years-100524911778?src=cluster&sid=17158871129641640_3&xp=7&px=1",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Principal,
    ],
  },
  {
    domain: "Data Analyst",
    company_name: "EA Games",
    job_title: "Data Analyst ( On Contract )",
    salary: "Above Market standards",
    experience: "2-3 Years",
    city: "Hyderabad",
    wfh: "Hybrid",
    Column11:
      "https://www.naukri.com/job-listings-data-analyst-on-contract-electronic-arts-hyderabad-2-to-3-years-210920500361?src=cluster&sid=17158871129641640_5&xp=2&px=2",
    experience_category: [ExperienceCategory.Associate],
  },
  {
    domain: "Data Analyst",
    company_name: "FedEx TSCS (India) Pvt Ltd",
    job_title: "Data Analyst",
    salary: "Salary offered - 9LPA+",
    experience: "1-4 Years",
    city: "Multiple Locations",
    wfh: "Hybrid",
    Column11:
      "https://www.naukri.com/job-listings-data-analyst-fedex-tscs-india-pvt-ltd-mumbai-new-delhi-pune-bengaluru-1-to-4-years-150524500255?src=cluster&sid=17158871129641640_7&xp=4&px=1",
    experience_category: [
      ExperienceCategory.Senior,
      ExperienceCategory.Associate,
    ],
  },
];
